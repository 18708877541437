.splashWrapper .MuiSkeleton-wave {
  transform: none;
  border-radius: unset;
  height: 15px;
}
.splashWrapper .MuiSkeleton-wave::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255 255 255 / 40%),
    transparent
  );
}
