.projectSkeletonMain {
    background-color: #efefef;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.projectSkeletonMain .MuiSkeleton-text {
    transform: none;
    border-radius: 3px;
}

.projectSkeletonMain .projectSkeletonHeader {
    min-height: 70px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    padding-right: 10px;
}

.projectSkeletonHeader ul,
.projectSkeletonHeader li {
    padding: 0 !important;
}

.projectSkeletonContainer {
    height: calc(100% - 65px);
    width: 100%;
    display: flex;
    align-items: center;
}

.projectSkeletonContainer .SkeletonContainer1,
.SkeletonContainer2,
.skeletonContainer3 {
    display: inline-block;
    height: 100%;
    text-align: center;
}

.projectSkeletonContainer hr {
    display: inline-block;
    height: 100%;
}

.SkeletonContainer2 {
    position: relative;
}

.projectSkeletonContainer .MuiSkeleton-root {
    margin: 15px;
}

.projectSkeletonContainer .skeletonArrow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #efefef;
}

.projectSkeletonContainer .skeletonArrow .MuiSkeleton-root {
    float: right;
}

.projectSkeletonMain .main-header {
    display: flex;
    align-items: center;
    width: 50vw;
}

.projectSkeletonMain .header-title-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}