/* TO DELETE IF BOOTSTRAP 4 */
button.dropdown-item {
    background: none;
    border: none;
    color: #000;
    padding: 5px;
    display: block;
    width: max-content;
}

@media screen and (min-device-width: 768px)
 {
  .menuBreak {
    top:-20vh!important;
    left: 6vh!important;
    right:5vh!important;
    bottom:5vh!important;
  }
}

@media screen and (max-device-width: 767px)
 {
  .menuBreak {
    top:-10vh!important;
    left: 5vh!important;
    right:5vh!important;
    bottom:5vh!important;
  }
}
